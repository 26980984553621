import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Message } from 'src/app/objects/message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private firestore: AngularFirestore) { }

  sendMessage(message: Message) {
    message.id = this.firestore.createId();

    const json = JSON.parse(JSON.stringify(message));

    this.firestore.collection('messages').add(json);
  }
}
