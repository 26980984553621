
<h1>add new trip report</h1>
<div class="outer-container">
    <mat-horizontal-stepper #stepper>
        <mat-step label="Basic trip info">

            <mat-form-field class="inline">
                <mat-label>Title</mat-label>
                <input matInput [(ngModel)]="trip.title" name="title">
            </mat-form-field>

            <mat-form-field class="inline">
                <mat-label>id</mat-label>
                <input matInput [(ngModel)]="trip.id" name="id">
            </mat-form-field>
            
            <mat-form-field class="inline">
                <mat-label>Sport</mat-label>
                <mat-select multiple [(value)]="trip.sports">
                  <mat-option *ngFor="let sport of sportsList" [value]="sport">{{sport}}</mat-option>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field class="inline">
                <mat-label>Location</mat-label>
                <mat-select [(value)]="trip.location">
                  <mat-option *ngFor="let loc of locsList" [value]="loc">{{loc}}</mat-option>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field class="date-picker">
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="trip.date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        
            <mat-form-field class="blurb">
                <mat-label>Blurb</mat-label>
                <textarea matInput placeholder="Type a blurb about the trip..." [(ngModel)]="trip.blurb"></textarea>
            </mat-form-field>
        
            <br>
            <div *ngIf="thumbnailUploadComplete">Thumbnail uploaded ✓</div>
            <div class="thumbnail-section" *ngIf="!thumbnailUploadComplete">
                <input #thumbnailInput hidden="true" type="file" onclick="this.value=null" (change)="thumbnailChange($event)" accept="image/*"/>
                <button class="upload-button" mat-flat-button (click)="thumbnailInput.click()">Choose a thumbnail</button>
                <image-cropper class="cropper"
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [resizeToWidth]="500"
                        [resizeToHeight]="500"
                        [aspectRatio]="1"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                ></image-cropper>
        
                <img [src]="croppedImage">
                <br>
                <mat-form-field class="inline">
                    <mat-label>Thumbnail alt text</mat-label>
                    <input matInput [(ngModel)]="trip.thumbnailAlt" name="thumbnailAlt">
                </mat-form-field>
                <br>
                <button mat-flat-button (click)="onUploadThumbnailClick()">Crop and Upload</button>
                <mat-progress-bar class="progress-bar" max="100" [value]="(uploadProgress | async)"></mat-progress-bar>
            </div>
            <button mat-button matStepperNext (click)="nextButtonClicked()">Next</button>
        </mat-step>

        <mat-step label="Trip report">
            <div id="text-editor">
                <ckeditor [editor]="Editor" [(ngModel)]="trip.body"></ckeditor>
            </div>
            
            <button mat-button matStepperPrevious>Back</button>
            <button mat-raised-button color="primary" (click)="stepper.reset(); publish()">Publish</button>
        </mat-step>
    </mat-horizontal-stepper>
    
</div>
