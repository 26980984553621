import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { RecipesComponent } from './views/recipes/recipes.component';
import { TripsComponent } from './views/trips/trips.component';
import { WritingComponent } from './views/writing/writing.component';
import { TripReportDetailComponent } from './views/trip-report-detail/trip-report-detail.component';
import { ContactComponent } from './views/contact/contact.component';
import { BlogComponent } from './views/blog/blog.component';
import { BlogDetailComponent } from './views/blog-detail/blog-detail.component';
import { AdminComponent } from './views/admin/admin.component';
import { AuthComponent } from './views/auth/auth.component';
import { AuthGuard } from './services/auth-guard.service';
import { EditorComponent } from './views/editor/editor.component';
import { NewTripComponent } from './views/new-trip/new-trip.component';
import { OrbitComponent } from './views/orbit/orbit.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'recipes', component: RecipesComponent },
  { path: 'writing', component: WritingComponent },
  { path: 'trip-reports', component: TripsComponent },
  { path: 'trip-reports/:name', component: TripReportDetailComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'writing', component: WritingComponent },
  { path: 'orbit', component: OrbitComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:name', component: BlogDetailComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'auth', component: AuthComponent },
  { path: 'admin/editor', component: EditorComponent, canActivate: [AuthGuard] },
  { path: 'admin/new-trip', component: NewTripComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
