export class TripReport {
    id: string;
    
    body: string = "";
    blurb: string;
    
    title: string;
    titleForUrl: string;

    date: moment.Moment;
    dateString: string;

    location: string;
    sports: string[];

    thumbnailFilepath: string;
    thumbnailUrl: string;
    thumbnailAlt: string;
}
