import { Component, OnInit } from '@angular/core';
import { Message } from 'src/app/objects/message';
import { MessageService } from 'src/app/services/message.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  message = new Message();

  constructor(private messageService: MessageService, private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle("Send me a message! \u2014 Pat Hansen");
    this.meta.addTag({name: 'description', content: 'If you have a question or comment, feel free to send me a message! It could be about anything on my site or anything not on my site too.'});
  }

  sendMessage() {
    this.messageService.sendMessage(this.message);
    this.message = new Message();
  }

}
