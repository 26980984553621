import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { TripReport } from 'src/app/objects/trip-report';
import { MiscService } from 'src/app/services/misc.service';
import { TripReportService } from 'src/app/services/trip-report.service';

import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
  selector: 'app-new-trip',
  templateUrl: './new-trip.component.html',
  styleUrls: ['./new-trip.component.scss']
})
export class NewTripComponent implements OnInit {

  trip = new TripReport();

  sportsList = [];
  locsList = [];

  // Thumbnail
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageFile;
  uploadProgress: Observable<number>;
  thumbnailUploadComplete = false;
  
  // Editor
  Editor = ClassicEditor;
  
  constructor(private tripsService: TripReportService, private miscService: MiscService) { }

  ngOnInit(): void {
    this.getSportsAndLocations();
  }

  getSportsAndLocations() {
    this.miscService.getTripFilters().pipe(take(1)).subscribe(filters => {
      this.sportsList = filters.sports;
      this.sportsList.splice(this.sportsList.indexOf("All"), 1);
      this.locsList = filters.locations;
      this.locsList.splice(this.locsList.indexOf("All"), 1);
    });
  }

  nextButtonClicked() {
    // Add computed properties.
    this.trip.titleForUrl = this.trip.title.replace(/\s+/g, '-').toLowerCase();
    this.trip.dateString = this.trip.date.format('MMMM D, YYYY');
  }

  publish() {
    this.reAddHtmlTags();
    this.tripsService.addNewTripReport(this.trip);
    this.trip = new TripReport();
    this.thumbnailUploadComplete = false;
  }

  ///////////////////////
  // Thumbnail upload. //
  ///////////////////////
  thumbnailChange(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    // Store the cropped version so it can be previewed below the full size image.  
    this.croppedImage = event.base64;

    // Convert the base64 string to a file.
    this.croppedImageFile = this.base64ToFile(event.base64, "t.png")
  }

  onUploadThumbnailClick() {
    // Set filepath on trip object and upload thumbnail.
    this.trip.thumbnailFilepath = 'trip-reports/' + this.trip.id + '/t.png';
    let map = this.tripsService.uploadThumbnailImage(this.croppedImageFile, this.trip.thumbnailFilepath);
    
    // Display upload progress.
    this.uploadProgress = map['task'].percentageChanges();
    
    // When task is finished, get the download url from the ref returned by the upload service call,
    //  and set the trip object's url value.
    map['task'].snapshotChanges().pipe(finalize(() => {
      map['ref'].getDownloadURL().pipe(take(1)).subscribe(url => {
        this.trip.thumbnailUrl = url;
        
        // Set the process complete so it will hide.
        this.thumbnailUploadComplete = true;
      });
    })).subscribe();

    
  }

  // Helper to convert base64 string into file for upload to Firebase.
  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  ////////////
  // Editor //
  ////////////
  reAddHtmlTags() {
    this.trip.body = this.trip.body.replace (/&lt;/g, "<");
    this.trip.body = this.trip.body.replace(/&gt;/g, ">");
  }
}
