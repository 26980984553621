import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';
import { take } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MiscService } from 'src/app/services/misc.service';
import { Blog } from 'src/app/objects/blog';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit, OnDestroy {

  allBlogs: Blog[] = [];
  filteredBlogs: Blog[] = [];
  tags = [];
  currentTag = "All";

  subscription = new Subscription();

  constructor(private blogService: BlogService, private titleService: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle('Blog \u2014 Pat Hansen')
    this.meta.addTag({name: 'description', content: 'Place to write things.'});
    
    this.subscription.add(this.blogService.getAllBlogs().subscribe(blogs => {
      this.allBlogs = blogs;
      this.filteredBlogs = blogs;
    }));

    this.blogService.getBlogTags().pipe(take(1)).subscribe(resp => {
      this.tags = resp.tags;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onNewTag() {
    this.filteredBlogs = this.allBlogs.filter(blog => blog.tags[this.currentTag])
  }
}
