// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDpwW3x65sOX0vLdLkLskpkmVhrPuJR2Cs",
    authDomain: "personal-site-73.firebaseapp.com",
    databaseURL: "https://personal-site-73.firebaseio.com",
    projectId: "personal-site-73",
    storageBucket: "personal-site-73.appspot.com",
    messagingSenderId: "263720738580",
    appId: "1:263720738580:web:d8009f6ee8837f6c15ac01",
    measurementId: "G-NTSH1BV8YT"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
