
<h1>add new {{editorType}}</h1>
<div class="editor-container">
    <mat-form-field class="inline">
        <mat-label>Title</mat-label>
        <input matInput [(ngModel)]="title" name="title">
    </mat-form-field>
    <mat-form-field class="inline">
        <mat-label>id</mat-label>
        <input matInput [(ngModel)]="id" name="id">
    </mat-form-field>

    <div id="editor" class="editor" contenteditable="true" (input)="body = $event.target.textContent"></div>
    
    <mat-form-field class="tags-input">
        <mat-label>Tags</mat-label>
        <mat-select multiple [(value)]="selectedTags">
          <mat-option *ngFor="let tag of tagsList" [value]="tag">{{tag}}</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="buttons-container">
        <button mat-raised-button color="primary" (click)="publish(editorType)">Publish {{ editorType }}</button>
    </div>
</div>
