export class Blog {
    title: string;
    urlTitle: string;
    id: string;
    tags = {};
    body: string;
    date: number;
    prettyDate: string;
    preview: string;
    tagsList = [];
}
