<div class="blog-page-container">
    <div class="search-container">
        <span>Filter by: </span>
        <mat-form-field>
            <mat-label>Tags</mat-label>
            <mat-select [(ngModel)]="currentTag" name="tags" (selectionChange)="onNewTag()">
                <mat-option *ngFor="let tag of tags" [value]="tag">{{ tag }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="blogs-container">
        <div *ngFor="let blog of filteredBlogs">
            <a routerLink="{{ blog.urlTitle }}">
                <div class="blog">
                    <span class="title">{{blog.title}}</span>
                    <span class="date">{{blog.prettyDate}}</span>
                    <div class="preview">{{blog.preview}}</div>
                    <div class="tags-row">Tags:
                        <span class="tag" *ngFor="let tag of blog.tagsList">{{tag}}</span>
                    </div>
                    <br>
                    <mat-divider></mat-divider>
                </div>
            </a>
        </div>
    </div>
</div>
