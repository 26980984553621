import { OrbitalBody, Vector2D } from "./models";

const G = 6.6743e-11; // Gravitational constant

export class OrbitalEngine {
  public bodies: OrbitalBody[];
  private dt: number;

  constructor(bodies: OrbitalBody[], dt: number) {
    this.bodies = bodies;
    this.dt = dt;
  }

  public step() {
    this.RK4();
  }

  public setDt(dt: number) {
    this.dt = dt;
  }

  // Between one body and every other body
  private computeNetAcceleration(
    body: OrbitalBody,
    otherBodies: OrbitalBody[]
  ): Vector2D {
    let netAccel: Vector2D = { x: 0, y: 0 };

    for (const other of otherBodies) {
      if (body.name === other.name) continue;
      const accel = this.computeGravitationalAcceleration(body, other);
      netAccel.x += accel.x;
      netAccel.y += accel.y;
    }

    return netAccel;
  }

  // Between two bodies
  private computeGravitationalAcceleration(
    body1: OrbitalBody,
    body2: OrbitalBody
  ): Vector2D {
    const dx = body2.position.x - body1.position.x;
    const dy = body2.position.y - body1.position.y;
    const r = Math.sqrt(dx * dx + dy * dy);

    if (r === 0) return { x: 0, y: 0 }; // Prevent division by zero

    const forceMagnitude = (G * body1.mass * body2.mass) / (r * r * r);

    const ax = (forceMagnitude / body1.mass) * dx;
    const ay = (forceMagnitude / body1.mass) * dy;

    return { x: ax, y: ay };
  }

  private RK4() {
    let k1s: any[] = [];
    let k2s: any[] = [];
    let k3s: any[] = [];
    let k4s: any[] = [];

    // k1 calculations
    for (const body of this.bodies) {
      const k1_accel = this.computeNetAcceleration(body, this.bodies);
      const k1_vel = { x: body.velocity.x, y: body.velocity.y };

      k1s.push({
        dx: this.dt * k1_vel.x,
        dy: this.dt * k1_vel.y,
        dvx: this.dt * k1_accel.x,
        dvy: this.dt * k1_accel.y,
      });
    }

    // k2 calculations
    for (let i = 0; i < this.bodies.length; i++) {
      const tempBody = {
        ...this.bodies[i],
        position: {
          x: this.bodies[i].position.x + 0.5 * k1s[i].dx,
          y: this.bodies[i].position.y + 0.5 * k1s[i].dy,
        },
        velocity: {
          x: this.bodies[i].velocity.x + 0.5 * k1s[i].dvx,
          y: this.bodies[i].velocity.y + 0.5 * k1s[i].dvy,
        },
      };
      const k2_accel = this.computeNetAcceleration(tempBody, this.bodies);

      k2s.push({
        dx: this.dt * tempBody.velocity.x,
        dy: this.dt * tempBody.velocity.y,
        dvx: this.dt * k2_accel.x,
        dvy: this.dt * k2_accel.y,
      });
    }

    // k3 calculations
    for (let i = 0; i < this.bodies.length; i++) {
      const tempBody = {
        ...this.bodies[i],
        position: {
          x: this.bodies[i].position.x + 0.5 * k2s[i].dx,
          y: this.bodies[i].position.y + 0.5 * k2s[i].dy,
        },
        velocity: {
          x: this.bodies[i].velocity.x + 0.5 * k2s[i].dvx,
          y: this.bodies[i].velocity.y + 0.5 * k2s[i].dvy,
        },
      };
      const k3_accel = this.computeNetAcceleration(tempBody, this.bodies);

      k3s.push({
        dx: this.dt * tempBody.velocity.x,
        dy: this.dt * tempBody.velocity.y,
        dvx: this.dt * k3_accel.x,
        dvy: this.dt * k3_accel.y,
      });
    }

    // k4 calculations
    for (let i = 0; i < this.bodies.length; i++) {
      const tempBody = {
        ...this.bodies[i],
        position: {
          x: this.bodies[i].position.x + k3s[i].dx,
          y: this.bodies[i].position.y + k3s[i].dy,
        },
        velocity: {
          x: this.bodies[i].velocity.x + k3s[i].dvx,
          y: this.bodies[i].velocity.y + k3s[i].dvy,
        },
      };
      const k4_accel = this.computeNetAcceleration(tempBody, this.bodies);

      k4s.push({
        dx: this.dt * tempBody.velocity.x,
        dy: this.dt * tempBody.velocity.y,
        dvx: this.dt * k4_accel.x,
        dvy: this.dt * k4_accel.y,
      });
    }

    // Apply updates to the bodies
    for (let i = 0; i < this.bodies.length; i++) {
      this.bodies[i].position.x +=
        (k1s[i].dx + 2 * k2s[i].dx + 2 * k3s[i].dx + k4s[i].dx) / 6;
      this.bodies[i].position.y +=
        (k1s[i].dy + 2 * k2s[i].dy + 2 * k3s[i].dy + k4s[i].dy) / 6;

      this.bodies[i].velocity.x +=
        (k1s[i].dvx + 2 * k2s[i].dvx + 2 * k3s[i].dvx + k4s[i].dvx) / 6;
      this.bodies[i].velocity.y +=
        (k1s[i].dvy + 2 * k2s[i].dvy + 2 * k3s[i].dvy + k4s[i].dvy) / 6;
    }
  }
}
