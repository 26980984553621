import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  titles = ["p@ hansen", "p47 h4n53n", "hat pansen", "part hunsen"];
  opened: boolean;

  public constructor(private titleService: Title) { }

  ngOnInit () {
    let num = Math.floor(Math.random() * this.titles.length);
    //this.setTitle(this.titles[num]);
    this.setTitle("Pat Hansen");
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
