import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { environment } from "../environments/environment";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./views/home/home.component";
import { RecipesComponent } from "./views/recipes/recipes.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSliderModule } from "@angular/material/slider";
import { TripsComponent } from "./views/trips/trips.component";
import { WritingComponent } from "./views/writing/writing.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { TripReportDetailComponent } from "./views/trip-report-detail/trip-report-detail.component";
import { ContactComponent } from "./views/contact/contact.component";
import { FormsModule } from "@angular/forms";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
} from "@angular/fire/analytics";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { BlogComponent } from "./views/blog/blog.component";
import { BlogDetailComponent } from "./views/blog-detail/blog-detail.component";
import { AdminComponent } from "./views/admin/admin.component";
import { AuthComponent } from "./views/auth/auth.component";
import { EditorComponent } from "./views/editor/editor.component";
import { NewTripComponent } from "./views/new-trip/new-trip.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { OrbitComponent } from "./views/orbit/orbit.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RecipesComponent,
    TripsComponent,
    WritingComponent,
    TripReportDetailComponent,
    ContactComponent,
    BlogComponent,
    BlogDetailComponent,
    AdminComponent,
    AuthComponent,
    EditorComponent,
    NewTripComponent,
    OrbitComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSidenavModule,
    MatCardModule,
    MatSelectModule,
    MatDividerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatStepperModule,
    MatCheckboxModule,
    MatSliderModule,
    ImageCropperModule,
    FormsModule,
    CKEditorModule,
  ],
  providers: [ScreenTrackingService],
  bootstrap: [AppComponent],
})
export class AppModule {}
