import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { take } from 'rxjs/operators';
import { Blog } from 'src/app/objects/blog';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {

  blog = new Blog();

  constructor(private blogService: BlogService, private route: ActivatedRoute, private titleService: Title, private meta: Meta) { }

  ngOnInit(): void {
    const title = this.route.snapshot.paramMap.get('name');

    this.blogService.getBlogForTitle(title).pipe(take(1)).subscribe(blogs => {
      this.blog = blogs[0];
      let bodyDiv = document.getElementById("body");
      bodyDiv.innerHTML = this.blog.body;
      this.titleService.setTitle(this.blog.title + ' \u2014 Pat Hansen')
      this.meta.addTag({name: 'description', content: this.blog.preview});
    }); 
  }
}
