<div id="writing-container">
    <div class="writing-list-item" *ngFor="let writing of writings">
        <a href="{{ writing.link }}" target="_blank">
            <div class="individual-writing-container">
                <img id="{{ writing.id }}" class="thumbnail" [src]="writing.thumbnailUrl" alt="Snapshot of {{ writing.title }}">
                <div class="title-container">
                    <div class="writing-title">{{ writing.title }}</div>
                    <div class="writing-date">{{ writing.date }}</div>
                    <div class="publication">{{ writing.publication }}</div>
                </div>
                <div class="writing-blurb">{{ writing.blurb }}</div>
            </div>
        </a>
    </div>    
</div>

