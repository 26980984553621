<div id="container">
    <div id="title">Send me a message!</div>
    <form id="contact-form">
        <mat-form-field class="full-width">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="message.name" name="name" autocorrect="off">
        </mat-form-field>
        <br>
        <mat-form-field class="full-width">
            <mat-label>Email</mat-label>
            <input matInput placeholder="So I can return your message" [(ngModel)]="message.email" name="email" autocapitalize="off">
        </mat-form-field>
        <br>
        <mat-form-field class="full-width">
            <mat-label>Subject</mat-label>
            <input matInput [(ngModel)]="message.subject" name="subject">
        </mat-form-field>
        <br>
        <mat-form-field class="full-width">
            <mat-label>Message</mat-label>
            <textarea matInput placeholder="What's on your mind?" [(ngModel)]="message.message" name="subject"></textarea>
        </mat-form-field>
        <br>
        <button id="send-button" mat-raised-button color="primary" (click)="sendMessage()">Send your email</button>
    </form>

</div>