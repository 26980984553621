<div id="container">
  <canvas></canvas>
  <div id="controls">
    <div id="body-info-panel">
      <div class="body-info" *ngFor="let body of engine.bodies">
        <mat-checkbox
          [(ngModel)]="body.visible"
          [ngStyle]="{ color: body.color }"
          color="error"
          >{{ body.name }}</mat-checkbox
        >
        <div
          class="position"
          [ngStyle]="{ color: body.color }"
          *ngIf="showPositions"
        >
          <div>
            | x: {{ round(body.position.x * scaleFactor) }} y:
            {{ round(body.position.y * scaleFactor) }} |
          </div>
        </div>
      </div>
    </div>
    <button mat-button (click)="togglePositions()">
      {{ showPositionsText }} positions
    </button>
    <br />
    <button mat-button (click)="toggleTrails()">
      {{ showTrailsText }} trails
    </button>
    <br />
    <button mat-button (click)="togglePlay()">{{ playText }}</button>
    <br />
    <button mat-button (click)="onReset()">Reset orbs</button>
    <br />
    <mat-slider
      [(ngModel)]="sliderValue"
      (input)="onSliderChange($event)"
      min="20"
      max="200"
      step="1"
    >
    </mat-slider>
  </div>
</div>
