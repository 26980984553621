import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage'
import { Observable } from 'rxjs';
import { TripReport } from '../objects/trip-report';


@Injectable({
  providedIn: 'root'
})
export class TripReportService {

  constructor(private firestore: AngularFirestore, private storage: AngularFireStorage) { }

  getTripReportSummaries(): Observable<any[]> {
    return this.firestore.collection('trip-reports', ref => ref.orderBy('date', 'desc')).valueChanges();
  }

  getImageForUrl(url: string): Observable<string> {
    return this.storage.ref(url).getDownloadURL();
  }

  getTripReportForTitle(title: string): Observable<any[]> {
    return this.firestore.collection('trip-reports', ref => ref.where('titleForUrl', '==', title)).valueChanges();
  }

  // Setters
  updateTripReport(trip: TripReport) {
    this.firestore.collection('trip-reports').doc(trip.id).update(trip);
  }

  addNewTripReport(trip: TripReport) {
    let json = JSON.parse(JSON.stringify(trip));
    this.firestore.collection('trip-reports').doc(trip.id).set(json);
  }

  uploadThumbnailImage(image, filepath: string): any {
    let ref = this.storage.ref(filepath);
    let task = this.storage.upload(filepath, image);
    return {task: task, ref: ref};
  }
}
