import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';
import { TripReportService } from 'src/app/services/trip-report.service';
import { Blog } from 'src/app/objects/blog';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

  editorType: string = "blog";
  body: string = "";
  title: string;
  id: string;

  tagsList = [];
  selectedTags = ["All"];

  constructor(private blogService: BlogService, private tripReportService: TripReportService) { }

  ngOnInit(): void {
    if (history.state.data) {
      this.editorType = history.state.data["type"];
    }
    this.getTagsList();
  }

  getTagsList() {
    this.blogService.getBlogTags().pipe(take(1)).subscribe(tags => {
      this.tagsList = tags.tags;
    });
  }

  publish(type: string) {
    if (type == "blog") {
      let blog = new Blog();
      blog.body = this.body;
      blog.preview = blog.body.slice(0, 200);
      blog.id = this.id;
      blog.title = this.title;
      blog.urlTitle = this.makeUrlTitle(blog.title);
      blog.date = this.makeDate();
      blog.prettyDate = this.prettyDate(blog.date);
      blog.tagsList = this.selectedTags;
      blog.tags = this.makeTags();
      blog.preview = this.makePreview();

      this.blogService.addBlog(blog);
    } else if (type == "trip") {

    }

    this.title = null;
    this.id = null;
    this.body = "";
    document.getElementById("editor").innerHTML = "";
    this.selectedTags = ["All"];
  }

  makeDate(): number {
    let n = "";
    let d = new Date();
    n += d.getFullYear();
    n += ("0" + (d.getMonth() + 1)).slice(-2);
    n += ("0" + d.getDate()).slice(-2);
    return parseInt(n);
  }

  prettyDate(julian: number): string {
    let year = Math.floor(julian / 10000);
    let month = Math.floor(julian / 100 % 100)
    let day = Math.floor(julian % 100);
    
    let options = { month: 'long', day: 'numeric', year: 'numeric'};
    let date = new Date();
    date.setFullYear(year, month - 1, day);

    return date.toLocaleDateString("en-US", options);
  }

  makeUrlTitle(input: string): string {
    let title = input.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
    return title.replace(/\s+/g, '-').toLowerCase();
  }

  makeTags() {
    let map = {};

    this.selectedTags.forEach(tag => {
      map[tag] = true;
    });

    return map;
  }

  makePreview(): string {
    return this.stripHtml(this.body).slice(0, 200);
  }

  stripHtml(html: string) {
    var temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  // toolbarButtonClicked(b: string) {
  //   switch (b) {
  //     case "B":
  //       this.body += "<b></b>";
  //       break;
  //     case "I":
  //       this.body += "<i></i>";
  //       break;
  //     case "p":
  //       this.body += "<p></p>";
  //       break;
  //     case "a":
  //       this.body += "<a href=''></a>";
  //       break;
  //   }
  // }
}
