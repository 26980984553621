import { OrbitalBody, PositionHistoryQueue, createOrbitalBody } from "./models";

const sun = createOrbitalBody({
  name: "Sun",
  mass: 1.989e30,
  radius: 25,
  position: { x: 0, y: 0 },
  velocity: { x: 0, y: 0 },
  color: "#ffcc00",
});

const earth = createOrbitalBody({
  name: "Earth",
  mass: 5.972e24,
  radius: 5,
  position: {
    x: 1.47e11, // at perihelion
    y: 0,
  },
  velocity: {
    x: 0,
    y: 30290, // speed in m/s at perihelion
  },
  color: "#287ab8",
  trailLength: 350,
});

const mars = createOrbitalBody({
  name: "Mars",
  mass: 6.39e23,
  radius: 4,
  position: {
    x: 2.066e11, // 1.52 AU at perihelion
    y: 0,
  },
  velocity: {
    x: 0,
    y: 26506, // speed in m/s at perihelion
  },
  color: "#9c2e35",
  trailLength: 600,
});

const mercury = createOrbitalBody({
  name: "Mercury",
  mass: 3.285e23,
  radius: 2,
  position: {
    x: 46e9, // 0.307 AU aka 46 million meters (at perihelion)
    y: 0,
  },
  velocity: {
    x: 0,
    y: 58980, // 58.98 km/s (at perihelion)
  },
  color: "#a99e93",
});

const venus = createOrbitalBody({
  name: "Venus",
  mass: 4.867e24,
  radius: 3,
  position: {
    x: 107.48e9, // 0.718 AU aka 107.48 million meters
    y: 0,
  },
  velocity: {
    x: 0,
    y: 35260, // 35.26 km/s
  },
  color: "#e5c68b",
  trailLength: 200,
});

const jupiter = createOrbitalBody({
  name: "Jupiter",
  mass: 1.898e27,
  radius: 7,
  position: {
    x: 740.52e9, // 5.20 AU aka 740.52 million meters
    y: 0,
  },
  velocity: {
    x: 0,
    y: 13710, // 13.71 km/s (at perihelion)
  },
  color: "#e5c68b",
});

const bodies: OrbitalBody[] = [sun, mercury, venus, earth, mars, jupiter];

export const getBodies = (): OrbitalBody[] => {
  const rawBodies = JSON.parse(JSON.stringify(bodies));
  return rawBodies.map((body: any) => {
    body.pastPositions = new PositionHistoryQueue(body.trailLength);
    return body;
  });
};

/**
 * Constants
 * G = 6.674e-11 m^3 kg^-1 s^-2
 * Msun = 1.989e30 kg
 */
/**
 * Earth
 * perihelion: 147.1 million km
 * aphelion: 152.1 million km
 * semi-major axis: 149.6 million km ((perihelion + aphelion) / 2)
 */

/**
 * Mars
 * perihelion: 206.6 million km
 * aphelion: 249.2 million km
 * semi-major axis: 227.9 million km ((perihelion + aphelion) / 2)
 */
