export interface OrbitalBody {
  name: string;

  // physics props
  mass: number; // kg
  position: Vector2D; // m
  velocity: Vector2D; // m/s

  // drawing props
  radius: number;
  color: string;
  pastPositions?: PositionHistoryQueue;
  visible?: boolean;
  trailLength?: number;
}

export function createOrbitalBody(body: OrbitalBody) {
  return {
    ...body,
    visible: true,
    pastPositions: new PositionHistoryQueue(),
  };
}

export interface Vector2D {
  x: number;
  y: number;
}

export class PositionHistoryQueue {
  queue: Vector2D[] = [];
  maxLength: number;

  constructor(maxLength = 100) {
    this.maxLength = maxLength;
  }

  public push(position: Vector2D) {
    this.queue.push(position);
    if (this.queue.length > this.maxLength) {
      this.queue.shift();
    }
  }

  public clear() {
    this.queue = [];
  }
}
