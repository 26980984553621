import { Component, OnInit, OnDestroy } from '@angular/core';
import { WritingService } from 'src/app/services/writing.service';
import { Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-writing',
  templateUrl: './writing.component.html',
  styleUrls: ['./writing.component.scss']
})
export class WritingComponent implements OnInit, OnDestroy {

  writings = [];

  subscription = new Subscription();

  constructor(private writingService: WritingService, private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle("Things I Wrote \u2014 Pat Hansen");
    this.meta.addTag({name: 'description', content: 'Take a look and enjoy some of the writing that I\'ve had published.'});
    this.subscription.add(this.writingService.getWritingLinks().subscribe(writings => {
      this.writings = writings;
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
