import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TripReportService } from 'src/app/services/trip-report.service';
import { take } from 'rxjs/operators';
import { TripReport } from 'src/app/objects/trip-report';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-trip-report-detail',
  templateUrl: './trip-report-detail.component.html',
  styleUrls: ['./trip-report-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TripReportDetailComponent implements OnInit {

  tripReport = new TripReport();

  constructor(private route: ActivatedRoute, private tripReportService: TripReportService, private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.route.params.forEach(() => {
      const title = this.route.snapshot.paramMap.get('name');
      this.tripReportService.getTripReportForTitle(title).pipe(take(1)).subscribe((trips) => {
        this.tripReport = trips[0];
        this.setTitle(this.tripReport.title + " Trip Report \u2014 Pat Hansen");
        this.meta.addTag({name: 'description', content: this.tripReport.blurb});
        document.getElementById('body').innerHTML = this.tripReport.body;
      });
    });
  }

  setTitle(title: string) {
    this.titleService.setTitle(title)
  }
}
