
<nav id="nav-bar-container">
    <a id="home-link-container" class="nav-link" routerLink="home" (click)="drawer.close()">
        <div id="home-link">
            <mat-icon id="home-icon">home</mat-icon>
            <div id="home-text">Home</div>
        </div>
    </a>
    
    <div id="items">
        <ul class="list" routerLinkActive="active">
            <li class="nav-item"><a class="nav-link" routerLink="trip-reports">Trip Reports</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="blog">Blog</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="writing">Writing</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="orbit">Orbit</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="contact">Contact</a></li>
        </ul>
    </div>

    <button id="menu-button" mat-icon-button aria-label="Menu" (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
    </button>
</nav>

<div id="main">
    <mat-drawer-container hasBackdrop="false">
        <mat-drawer #drawer mode="push" position="end" (click)="drawer.close()">
            <div id="drawer">
                <ul id="menu-list">
                    <li class="menu-item"><a class="menu-link" routerLink="trip-reports">Trip Reports</a></li>
                    <li class="menu-item"><a class="menu-link" routerLink="blog">Blog</a></li>
                    <li class="menu-item"><a class="menu-link" routerLink="writing">Writing</a></li>
                    <li class="menu-item"><a class="menu-link" routerLink="orbit">Orbit</a></li>
                    <li class="menu-item"><a class="menu-link" routerLink="contact">Contact</a></li>
                </ul>
            </div>
        </mat-drawer>
        <mat-drawer-content (click)="drawer.close()">
            <router-outlet></router-outlet>
        </mat-drawer-content>
    </mat-drawer-container>
</div>

<div id="footer">
    <hr>
    <div id="social-icons">
        <a href="https://www.facebook.com/pathansen1618/" target="_blank">
            <img class="social-icon" src="assets/social-icons/facebook-icon.svg">
        </a>
        <a href="https://www.instagram.com/pathansen73/" target="_blank">
            <img class="social-icon" src="assets/social-icons/instagram-icon.svg">
        </a>
        <a href="https://www.linkedin.com/in/pathansen73/" target="_blank">
            <img class="social-icon" src="assets/social-icons/linkedin-icon.svg">
        </a>
    </div>
</div>
