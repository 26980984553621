import { Component, OnInit, OnDestroy } from '@angular/core';
import { TripReport } from 'src/app/objects/trip-report';
import { TripReportService } from 'src/app/services/trip-report.service';

import { Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { MiscService } from 'src/app/services/misc.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss']
})
export class TripsComponent implements OnInit, OnDestroy {

  tripReports: TripReport[] = [];
  filteredTripReports: TripReport[] = [];

  locations: string[] = [];
  currentLocation = "All";
  sports: string[] = [];
  currentSport = "All";

  subscription = new Subscription();

  constructor(private tripReportService: TripReportService, private titleService: Title, private meta: Meta, private miscService: MiscService) { }

  ngOnInit() {
    this.setTitle("Trip Reports \u2014 Pat Hansen");
    this.meta.addTag({name: 'description', content: 'Scroll through some of the trips I\'ve been on: backcountry skiing, rock climbing, ice climbing, bike touring, and others.'});
    this.subscription.add(
      this.tripReportService.getTripReportSummaries().subscribe(reports => {
        this.tripReports = reports;
        this.filteredTripReports = reports;
    }));

    this.miscService.getTripFilters().pipe(take(1)).subscribe(lists => {
      this.locations = lists.locations;
      this.sports = lists.sports;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }
  
  // Filtering
  onNewFilter() {
    if (this.currentLocation === "All" && this.currentSport === "All") {
      this.filteredTripReports = this.tripReports;
    } else if (this.currentLocation === "All") {
      this.filteredTripReports = this.tripReports.filter(trip => (trip.sports.indexOf(this.currentSport) != -1));
    } else  if (this.currentSport === "All") {
      this.filteredTripReports = this.tripReports.filter(trip => trip.location === this.currentLocation);
    } else {
      this.filteredTripReports = this.tripReports.filter(trip => ((trip.location === this.currentLocation) && (trip.sports.indexOf(this.currentSport) != -1)));
    }
  }
}
