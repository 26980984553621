import { Component, OnInit } from '@angular/core';
import { MiscService } from 'src/app/services/misc.service';
import { take } from 'rxjs/operators';
import { Testimonial } from 'src/app/objects/testimonial';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  testimonials: Testimonial[] = [];
  bio: string;

  // The first one is shipped with the initial site load so there's no
  //  skipping around between whichever images load fastest.
  urls: string[] = ["../../../assets/images/home-1.jpg", "../../../assets/images/home-2.jpg", "../../../assets/images/home-3.jpg", "../../../assets/images/home-4.jpg", "../../../assets/images/home-5.jpg", "../../../assets/images/home-6.jpg"];
  
  constructor(private miscService: MiscService, private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle("Welcome \u2014 Pat Hansen")
    this.meta.addTag({name: 'description', content: 'Welcome to the official site of Pat Hansen. Come check out my trip reports for good stories and more information about skiing and climbing missions.'});

    this.miscService.getTestimonials().pipe(take(1)).subscribe(tests => {
      this.testimonials = tests;
    });

    this.miscService.getBio().pipe(take(1)).subscribe(bio => {
      this.bio = bio.text;
    });

    // this.miscService.getHomeImages().pipe(take(1)).subscribe(images => {
    //   images.forEach(image => {
    //     this.urls.push(image.downloadUrl);
    //   });
    // });
  }
}
