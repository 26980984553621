import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {

  email: string;
  pw: string;

  authSubscription: Subscription;
  alreadyLoggedIn = false;


  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {

    this.authSubscription = this.authService.getAuthState().subscribe(user => {
      this.alreadyLoggedIn = user !== null;
    });
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  loginWithEmail() {
    this.authService.loginWithEmail(this.email, this.pw).then(res => {
      console.log(res);
      this.router.navigate(['admin']);
    });
  }

}
