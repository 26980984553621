<div id="home-container">
    <div id="image-container">
        <img id="image1" [src]="urls[0]" alt="Backcountry skiing in Montezuma, Colorado.">
        <img id="image2" [src]="urls[1]" alt="Ice climbing in Little Cottonwood Canyon.">
        <img id="image3" [src]="urls[2]" alt="Rock climbing in the Appalachians.">
        <img id="image4" [src]="urls[3]" alt="Rock climbing in Smith Rock Park, Oregon.">
        <img id="image5" [src]="urls[4]" alt="Ski touring in Mayflower, Colorado.">
        <img id="image6" [src]="urls[5]" alt="Skiing at Snowbird.">
        
    </div>

    <div class="home-sections-container">
        <div class="home-section">
            <div class="section-title">Guide</div>
            <div class="section-body">
                <div id="cert-container">
                    <div class="right-side">
                        <div class="text">
                            <p>AMGA Single Pitch Instructor</p>
                            <p>AMGA Apprentice Rock Guide</p>
                            <p>AAI Avalanche Course Instructor</p>
                        </div>
                    </div>
                    <img id="spi" src="../../../assets/images/certs/instructor_SPI_B&W.png" alt="Single Pitch Instructor logo">
                </div>
            </div>
        </div>
        <hr>
        <div class="home-section">
            <div class="section-title">Software Developer</div>
            <div class="section-body">
                <table>
                    <tr>
                        <td><b>Favorite languages</b></td>
                        <td>Python, Swift, TypeScript</td>
                    </tr>
                    <tr>
                        <td><b>Favorite applications</b></td>
                        <td>Frontend design, robotics, cryptography</td>
                    </tr>
                </table>
            </div>
        </div>
        <hr>
        <div class="home-section">
            <div class="section-title">Writer</div>
            <div class="section-body">Not like Charles Dickens, more like Bill Finnegan, John Long, or Liz Clark.</div>
        </div>
    </div>
    

    <!-- Testimonials - save for later -->
    <!-- <div id="testimonial-header">What are people saying about Pat?</div>
    <div id="testimonial-container">
        <mat-card class="testimonial" *ngFor="let testimonial of testimonials">
            <mat-card-header>
                <img mat-card-avatar [src]="testimonial.thumbnailDownloadUrl" alt="{{ testimonial.person }}'s headshot.'"> 
                <mat-card-title>{{ testimonial.person }}</mat-card-title>
                <mat-card-subtitle>{{ testimonial.place }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <p>"{{ testimonial.quote }}"</p>
            </mat-card-content>
        </mat-card>
    </div> -->
</div>