import { Component, ElementRef, OnInit } from "@angular/core";
import { Vector2D, OrbitalBody, PositionHistoryQueue } from "./src/models";
import { getBodies } from "./src/initial";
import { OrbitalEngine } from "./src/physics";

@Component({
  selector: "app-orbit",
  templateUrl: "./orbit.component.html",
  styleUrls: ["./orbit.component.scss"],
})
export class OrbitComponent implements OnInit {
  scaleFactor = 1e-9; // 1 pixel = 1e9 meters
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  center: Vector2D = { x: 0, y: 0 };
  engine: OrbitalEngine;

  constructor(private el: ElementRef) {
    this.engine = new OrbitalEngine(getBodies(), this.sliderValue * 1e3);
  }

  ngOnInit(): void {
    this.canvas = this.el.nativeElement.querySelector("canvas");
    this.ctx = this.canvas.getContext("2d");

    this.canvas.width = window.innerWidth - 20;
    this.canvas.height = window.innerHeight - 150;

    this.center = {
      x: this.canvas.width / 2,
      y: this.canvas.height / 2,
    };

    this.startDrawLoop();
  }

  startDrawLoop() {
    const animate = () => {
      this.draw();
      if (this.play) {
        requestAnimationFrame(animate);
      }
    };
    animate();
  }

  draw() {
    // Clear the canvas
    this.ctx.fillStyle = "black";
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    // Update the bodies
    this.engine.step();

    // Draw the bodies
    this.engine.bodies.forEach((body) => this.drawObject(body));
  }

  drawObject(object: OrbitalBody) {
    if (!object.visible) return;
    const displayPosition = {
      x: this.center.x + object.position.x * this.scaleFactor,
      y: this.center.y + object.position.y * this.scaleFactor,
    };

    if (this.showTrails) {
      object.pastPositions.push(displayPosition as Vector2D);
    } else {
      object.pastPositions.clear();
    }

    // Draw current position
    this.ctx.beginPath();
    this.ctx.arc(
      displayPosition.x,
      displayPosition.y,
      object.radius,
      0,
      2 * Math.PI
    );
    this.ctx.fillStyle = object.color;
    this.ctx.fill();

    // Draw past positions
    this.ctx.beginPath();
    object.pastPositions.queue.forEach((position) => {
      this.ctx.lineTo(position.x, position.y);
    });
    this.ctx.strokeStyle = object.color;
    this.ctx.stroke();
  }

  onReset() {
    this.engine = new OrbitalEngine(getBodies(), this.sliderValue * 1e3);
  }

  showPositions = false;
  showPositionsText = "Show";
  togglePositions() {
    this.showPositions = !this.showPositions;
    this.showPositionsText = this.showPositions ? "Hide" : "Show";
  }

  showTrails = false;
  showTrailsText = "Show";
  toggleTrails() {
    this.showTrails = !this.showTrails;
    this.showTrailsText = this.showTrails ? "Hide" : "Show";
  }

  play = true;
  playText = "Pause";
  togglePlay() {
    this.play = !this.play;
    this.playText = this.play ? "Pause" : "Play";

    if (this.play) {
      this.startDrawLoop();
    }
  }

  round(num: number) {
    return Math.round(num);
  }

  sliderValue = 100;
  onSliderChange(event: any) {
    this.sliderValue = event.value;
    this.engine.setDt(event.value * 1e3);
  }
}
