import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MiscService {

  constructor(private firestore: AngularFirestore) { }

  getTestimonials(): Observable<any[]> {
    return this.firestore.collection('testimonials').valueChanges();
  }

  getBio(): Observable<any> {
    return this.firestore.collection('misc').doc('bio').valueChanges();
  }

  getHomeImages(): Observable<any[]> {
    return this.firestore.collection('home-images').valueChanges();
  }

  getTripFilters(): Observable<any> {
    return this.firestore.collection('misc').doc('trips').valueChanges();
  }
}
