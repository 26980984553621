<div id="container">
    <div class="search-container">
        <span>Filter by: </span>
        <mat-form-field>
            <mat-label>Sport</mat-label>
            <mat-select [(ngModel)]="currentSport" name="sport" (selectionChange)="onNewFilter()">
                <mat-option *ngFor="let sport of sports" [value]="sport">{{ sport }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Location</mat-label>
            <mat-select [(ngModel)]="currentLocation" name="location" (selectionChange)="onNewFilter()">
                <mat-option *ngFor="let location of locations" [value]="location">{{ location }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="trips-container">
        <div class="list-item" *ngFor="let trip of filteredTripReports">
            <a routerLink="{{ trip.titleForUrl }}">
                <div class="trip-container">
                    <img id="{{ trip.id }}" class="thumbnail" [src]="trip.thumbnailUrl" alt="{{ trip.thumbnailAlt }}">
                    <div class="title-container">
                        <div class="trip-title">{{ trip.title }}</div>
                        <div class="trip-date">{{ trip.dateString }}</div>
                    </div>
                    <div class="trip-blurb">{{ trip.blurb }}</div>
                </div>
            </a>
        </div>    
        <div class="no-trips" *ngIf="filteredTripReports.length == 0">
            I haven't done that... yet!
        </div>
    </div>
</div>
