
<div id="auth-container">

    <span>Unless you are me, you can't login.</span>
    <div id="login-container">
        <form class="login-form">
            <h2>Login</h2>
            <mat-form-field class="text-input">
                <input matInput type="email" name="email" placeholder="Email" [(ngModel)]="email">
            </mat-form-field>
            <br>
            <mat-form-field class="text-input">
                <input matInput type="password" name="password" placeholder="Password" [(ngModel)]="pw">
            </mat-form-field>
            <br>
            <button mat-raised-button color="primary" (click)="loginWithEmail()">Login</button>
        </form>
    </div>
</div>
