import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Blog } from '../objects/blog';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private firestore: AngularFirestore) { }

  getAllBlogs(): Observable<any[]> {
    return this.firestore.collection('blog', ref => ref.orderBy("date", 'desc')).valueChanges();
  }

  updateBlog(blog: Blog) {
    this.firestore.collection('blog').doc(blog.id).set(blog);
  }

  getBlogForTitle(title: string): Observable<any[]> {
    return this.firestore.collection('blog', ref => ref.where("urlTitle", '==', title)).valueChanges();
  }

  addBlog(blog: Blog) {
    this.firestore.collection('blog').doc(blog.id).set(JSON.parse(JSON.stringify(blog)));
  }

  getBlogTags(): Observable<any> {
    return this.firestore.collection('misc').doc('blog').valueChanges();
  }
}
